import { Component, NgZone } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen'
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private router: Router,
    private zone: NgZone
    ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      await SplashScreen.hide({
        fadeOutDuration:200
      });
    });

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
          const slug = event.url.split(".la").pop();
          if (slug) {
              this.router.navigateByUrl(slug);
          }
      });
  });
  }
}
