import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { HelperService } from '../services/helper.service';

@Injectable({
  providedIn: 'root'
})
export class AutoLoginGuard implements CanLoad {
  constructor(
    private auth: AuthService,
    private router: Router
  ){}
  canLoad(): Observable<boolean>{
    return this.auth.isAuthenticated.pipe(
      filter(val => val !== null),
      take(1),
      map(isAuthenticated => {
        if (isAuthenticated) {
					this.router.navigateByUrl('/main', { replaceUrl: true });
				} else {
					return true;
				}
      })
    );
  }
}
