import { Injectable, NgZone } from '@angular/core';
import { Toast } from '@capacitor/toast';
import { LoadingController, NavController, AlertController } from '@ionic/angular';

export interface Settings {
  point: {
    view_post: number
  },
  post: {
    max_photos: number,
    validity_hours: number
  },
  google_map: string
}

@Injectable({
  providedIn: 'root'
})

export class HelperService {
  private settings: Settings;

  constructor(
    private ngZone: NgZone,
    private navController: NavController,
    private loadingCtrl: LoadingController,
    private alertController: AlertController,
    ) { }

  redirectRootForward(route: string) {
    this.ngZone.run(() => {
      this.navController.navigateRoot(route, {
        animated: true,
        animationDirection: 'forward'
      });
    });
  }

  pushPage(route: string, param?: any) {
    this.ngZone.run(() => {
      this.navController.navigateForward(route, {
        animated: true,
        state: { param }
      });
    });
  }

  goBack() {
    this.navController.pop();
  }

  async showAlert(message?: string, header?: string) {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: ['OK']
    });

    await alert.present();

    return alert;
  }

  async showToast(text: string) {
    return await Toast.show({
      text: text,
      position: 'top',
      duration: 'long'
    });
  }

  async loading(text?: string){
    const loading = await this.loadingCtrl.create({
      message: text,
    });

    await loading.present();

    return loading;
  }

  async blobToBase64(url: string): Promise<any>{

    const response = await fetch(url);
    const blob = await response.blob();

    return new Promise((resolve) => {
     
      const reader = new FileReader();

      reader.readAsDataURL(blob);
  
      reader.onloadend = function(){
        resolve(reader.result);
      }
    });
  };

  dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
  }

  setSettings(settings: Settings){
    console.log('settings Loaded!!')
    this.settings = settings;
  }

  getSettings(){
    return this.settings;
  }

  limitString(string, length : number = 60) {
    return string.length > length ? string.substring(0, length) + "..." : string;
  }
}