import { Injectable } from '@angular/core';
import { CanLoad, Router} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, take, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  constructor(
    private auth: AuthService,
    private router: Router
  ){}
  canLoad(): Observable<boolean>{
    return this.auth.isAuthenticated.pipe(
      filter((val) => val !== null),
      take(1),
			map((isAuthenticated) => {
				if (isAuthenticated) {
					return true;
				} else {
					this.router.navigateByUrl('/login');
					return false;
				}
			})
    );
  }
}
