//import { LoadingController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { AuthService } from "../services/auth.service";
import { Router } from '@angular/router';

@Injectable()

export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private auth: AuthService,
        private router: Router
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError(err => {
            if ([401, 403].includes(err.status) && this.auth.isAuthenticated.value) {
                // auto logout if 401 or 403 response returned from api
                this.closeModals();
                this.auth.logout();
            }

            if ([404].includes(err.status)) {
                this.router.navigateByUrl('404', { replaceUrl: true });
            }

            const error = err.error?.message || err.statusText;
            return throwError(() => error);
        }))
    }

    private closeModals(){
        var modals = document.getElementsByTagName("ion-modal");
        [].forEach.call(modals, function (el:any) {
            el.dismiss();
        });
    }
}