//import { LoadingController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { AuthService } from "../services/auth.service";

@Injectable()

export class HttpRequestInterceptor implements HttpInterceptor {

    constructor(
        private auth: AuthService,
        //private loadingCtrl: LoadingController
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        /* this.loadingCtrl.getTop().then(hasLoading => {
           if(!hasLoading){
               this.loadingCtrl.create({
                   spinner: 'circular',
                   translucent: true
               }).then(loading => loading.present())
           }
       }) */
        if (this.isInBlockedList(req.url)) {
            return next.handle(req);
        } else {
            return next.handle(this.addToken(req)).pipe(
                catchError(err => {
                    if (err instanceof HttpErrorResponse) {
                        switch ((<HttpErrorResponse>err).status) {
                            case 401:
                                if (err.status == 401) {
                                    this.auth.isAuthenticated.next(false);
                                }
                            default:
                                return throwError(() => err);
                        }
                    } else {
                        return throwError(() => err);
                    }
                }),
                finalize(() => {
                    /* this.loadingCtrl.getTop().then(hasLoading => {
                        if(hasLoading){
                            this.loadingCtrl.dismiss();
                        }
                    }) */
                })
            );
        }

    }

    isInBlockedList(url: string): Boolean {
        const unBlockedUrl = [
            `${environment.endpoint}user/login`,
        ]
        if (unBlockedUrl.includes(url)) {
            return true;
        } else {
            return false;
        }
    }

    addToken(req: HttpRequest<any>) {
        if (this.auth.token) {
            return req.clone({
                headers: new HttpHeaders({
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': `Bearer ${this.auth.token}`
                })
            });
        } else {
            return req;
        }
    }
}