import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Preferences } from '@capacitor/preferences';
import { BehaviorSubject, from, map, Observable, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';

const TOKEN_KEY = 'TOKEN';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = null;

  constructor(
    private http: HttpClient,
    private helper: HelperService,
    private router: Router
  ) {
    this.loadToken();
  }

  async loadToken() {
    const token = await Preferences.get({ key: TOKEN_KEY });
		if (token && token.value) {
			this.token = token.value;
			this.isAuthenticated.next(true);
		} else {
			this.isAuthenticated.next(false);
		}
  }

  login(credentials: {email, password}): Observable<any> {
    return this.http.post(`${environment.endpoint}user/login`, credentials).pipe(
			map((data: any) => data.access_token),
			switchMap((token) => {
				this.token = token;
				const storeToken = Preferences.set({ key: TOKEN_KEY, value: token });
				return from(Promise.all([storeToken]));
			}),
			tap((_) => {
				this.isAuthenticated.next(true);
			})
		);
  }

  logout(){
    Preferences.remove({key: TOKEN_KEY}).then(()=>{
      this.router.navigateByUrl('/login', { replaceUrl: true });
      this.isAuthenticated.next(false);
    })
  }
}
